@import "/src/index.css";

.app__newsletters {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    background-color: #f2f2f2;
}

.newsletters__center {
    width: 1200px;
    max-width: 100%;
    margin: auto;
    padding: 2rem 1rem;
}

.newsletters__head {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto 1rem;
}

.newsletters__head h1 {
    color: #54AFBC;
    text-align: left;
    font-family: var(--f-p);
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.newsletters__main {
    width: 100%;
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(3, 360px);
    justify-content: center;
    align-items: center;
    gap: 3rem 2.4rem;
}

.empty__list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    font-family: var(--f-p);
    font-size: 21px;
}

.page__load {
    width: 100%;
    max-width: 100%;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0.5rem;
}

.page__load p {
    font-family: var(--f-p);
    font-size: 24px;
}

@media all and (max-width : 1200px) {
    .newsletters__main {
        padding: 1rem 0;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media all and (max-width : 800px) {
    .newsletters__main {
        grid-template-columns: repeat(1, 380px);
    }
}

@media all and (max-width : 500px) {
    .newsletters__head h1 {
        font-size: 36px;
    }
}

@media all and (max-width : 400px) {
    .newsletters__main {
        grid-template-columns: repeat(1, 1fr);
    }
}